import { SVGProps } from 'react';
const UserCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props?.width || 20}
    height={props?.height || 20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    {...props}
  >
    <g clipPath="url(#clip0_612_44180)">
      <path
        d="M4.43021 16.199C4.93714 15.0047 6.12073 14.167 7.49996 14.167H12.5C13.8792 14.167 15.0628 15.0047 15.5697 16.199M13.3333 7.91699C13.3333 9.75794 11.8409 11.2503 9.99996 11.2503C8.15901 11.2503 6.66663 9.75794 6.66663 7.91699C6.66663 6.07604 8.15901 4.58366 9.99996 4.58366C11.8409 4.58366 13.3333 6.07604 13.3333 7.91699ZM18.3333 10.0003C18.3333 14.6027 14.6023 18.3337 9.99996 18.3337C5.39759 18.3337 1.66663 14.6027 1.66663 10.0003C1.66663 5.39795 5.39759 1.66699 9.99996 1.66699C14.6023 1.66699 18.3333 5.39795 18.3333 10.0003Z"
        stroke={props?.stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
    </g>
    <defs>
      <clipPath id="clip0_612_44180">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default UserCircleIcon;
