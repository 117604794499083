import { SVGProps } from 'react';
const UserGroup = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props?.width || 16}
    height={props?.height || 16}
    fill="none"
    stroke="currentColor"
    viewBox="0 0 16 16"
    {...props}
  >
    <path fill={props?.fill} d="M6.335 7.333a2.667 2.667 0 1 0 0-5.333 2.667 2.667 0 0 0 0 5.333Z" opacity={0.12} />
    <path
      stroke={props?.stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.333}
      d="M14.665 14v-1.333c0-1.243-.85-2.287-2-2.583m-2.333-7.89a2.668 2.668 0 0 1 0 4.945m1 6.861c0-1.242 0-1.864-.203-2.354a2.666 2.666 0 0 0-1.443-1.443C9.196 10 8.575 10 7.332 10h-2c-1.242 0-1.864 0-2.354.203-.653.27-1.172.79-1.443 1.443-.203.49-.203 1.111-.203 2.354m7.667-9.333a2.667 2.667 0 1 1-5.334 0 2.667 2.667 0 0 1 5.334 0Z"
    />
  </svg>
);
export default UserGroup;
