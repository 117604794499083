import { RootState } from '@/reducers'
import MenuBottom from './MenuBottom'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import { useEffect, useState } from 'react'
import { socket } from '@/shared/config/socket'
import { AppDispatch } from '@/store'
import { getProfile } from '../auth/auth.api'
import SModal from '@/components/shared/Modal/SModal'
import { CButton, CModalBody, CModalFooter } from '@coreui/react-pro'
import { ITransaction } from '@/shared/model/transaction.model'

import qua1 from '../../../assets/img/thongbaokhuyenmai.gif'
import ChatWidget from './ChatWidget'
import axios from '../../../shared/config/axios-interceptor'

const token =
  localStorage.getItem('authentication_token') ||
  sessionStorage.getItem('authentication_token')

const Layout = ({ children }: { children: React.ReactNode }) => {
  const { navigate, location } = useRouter()
  const dispatch = useDispatch<AppDispatch>()
  const { user } = useSelector((state: RootState) => state.authentication)
  const [visible, setVisible] = useState(false)
  const [deposit, setDeposit] = useState<ITransaction>()

  useEffect(() => {
    console.log('data1231323', user?.id)

    socket.on('connect', () => {
      console.log('Connected to server')
    })

    socket.on(`user_${user?.id}_new_message`, (newMsg: any) => {
      console.log('Bạn có tin nhắn mới: ', newMsg)
    })
    console.log('data', user?.id)

    socket.on(`user_${user?.id}_new_deposit`, (data: any) => {
      console.log('data', data)

      if (data) {
        dispatch(getProfile())
      }

      if (data && data.isNotify && data.message) {
        setDeposit(data)
        setVisible(true)
      }
    })

    return () => {
      // console.log('disconnect');
      // socket.off('connect')
      socket.off(`user_${user?.id}_new_message`)
      socket.off(`user_${user?.id}_new_deposit`)
      // socket.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const getNotiLatest = async () => {
    try {
      const response = await axios.get('/transaction/get-noti-latest')
      console.log('Success:', response.data)
      if (response?.data && response?.data?.isSeen === 0 && response?.data?.message) {
        setDeposit(response?.data)
        setVisible(true)
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }


  const handlerSeen = async () => {
    try {
      const response = await axios.get('/transaction/seen')
      console.log('Success:', response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getNotiLatest();
  }, [])

  const handleOnClose = () => {
    setVisible(false)
    handlerSeen()
  }

  return (
    <>
      <div className="container-web">
        {/* <div className='box-bg-2'>
          <img className="bg-2" src={bg2} />
        </div> */}

        {children}
        <div className="bottom-bar">
          <div className="info-current-user d-flex justify-content-between align-items-center">
            <div className="fw-semibold">ID: {user?.id}</div>
            <div className="fw-semibold">
              Số dư: {new Intl.NumberFormat('vi-VN').format(user?.money || 0)}
            </div>
          </div>
          <MenuBottom />
        </div>
      </div>
      <div>
        <SModal
          visible={visible}
          onClose={handleOnClose}
          backdrop="static"
          className="custom-modal custom-1"
          alignment="center"
        >
          {/* <CModalHeader>
            <h5>Thông báo Nạp Tiền</h5>
          </CModalHeader> */}
          <CModalBody>
            {/* <div className="text-center">
              <img width={120} src={qua1} />
            </div> */}

            <h1 className="text-center my-2"></h1>
            <p style={{ color: '#fff', fontSize: 17 }} className="mt-5">
              {deposit?.message}
            </p>
          </CModalBody>
          <CModalFooter className="justify-content-center">
            <CButton onClick={handleOnClose} style={{ background: '#FD0404' }}>
              Đồng ý
            </CButton>
          </CModalFooter>
        </SModal>
        {/* <ChatWidget /> */}
      </div>
    </>
  )
}

export default Layout
