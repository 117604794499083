import { SVGProps } from 'react';
const EmailIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props?.width || 20}
    height={props?.height || 20}
    viewBox="0 0 20 20"
    fill="none"
    stroke="currentColor"
    {...props}
  >
    <path
      opacity="0.12"
      d="M11.5305 10.5962C10.9796 10.9819 10.7041 11.1747 10.4044 11.2494C10.1397 11.3154 9.86288 11.3154 9.59819 11.2494C9.29853 11.1747 9.02304 10.9819 8.47207 10.5962L1.66797 5.83331C1.66797 4.4526 2.78726 3.33331 4.16797 3.33331H15.8346C17.2153 3.33331 18.3346 4.4526 18.3346 5.83331L11.5305 10.5962Z"
      fill={props?.fill}
    />
    <path
      d="M1.66797 5.83331L8.47207 10.5962C9.02304 10.9819 9.29853 11.1747 9.59819 11.2494C9.86288 11.3154 10.1397 11.3154 10.4044 11.2494C10.7041 11.1747 10.9796 10.9819 11.5305 10.5962L18.3346 5.83331M5.66797 16.6666H14.3346C15.7348 16.6666 16.4348 16.6666 16.9696 16.3942C17.44 16.1545 17.8225 15.772 18.0622 15.3016C18.3346 14.7668 18.3346 14.0668 18.3346 12.6666V7.33331C18.3346 5.93318 18.3346 5.23312 18.0622 4.69834C17.8225 4.22793 17.44 3.84548 16.9696 3.6058C16.4348 3.33331 15.7348 3.33331 14.3346 3.33331H5.66797C4.26784 3.33331 3.56777 3.33331 3.03299 3.6058C2.56259 3.84548 2.18014 4.22793 1.94045 4.69834C1.66797 5.23312 1.66797 5.93318 1.66797 7.33331V12.6666C1.66797 14.0668 1.66797 14.7668 1.94045 15.3016C2.18014 15.772 2.56259 16.1545 3.03299 16.3942C3.56777 16.6666 4.26784 16.6666 5.66797 16.6666Z"
      stroke={props?.stroke}
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default EmailIcon;
