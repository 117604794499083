import { SVGProps } from 'react';
const DotIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props?.width || 8}
    height={props?.height || 8}
    fill="currentColor"
    viewBox="0 0 8 8"
    {...props}
  >
    <circle cx={4} cy={4} r={3} fill={props?.color} />
  </svg>
);
export default DotIcon;
