import { RootState } from '@/reducers';
import { IChat } from '@/shared/model/chat.model';
import { IInitialState, IParams, IResponse } from '@/shared/shared-interfaces';
import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { getEntities, IChatParams } from './chat.api';

interface IChatInitialState extends IInitialState {
  filterState: IChatParams;
}

export const initialChatFilter: IChatParams = {
  page: 1,
  limit: 20,
  sortBy: 'lastModifiedDate',
  sortOrder: 'DESC',
};

const initialState: IChatInitialState = {
  fetchEntitiesSuccess: false,
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
  totalPages: 0,
  filterState: initialChatFilter,
};

export const chatAdapter = createEntityAdapter<IChat>({
  selectId: ({ id }) => id,
});

const { actions, reducer } = createSlice({
  name: 'chatSlice',
  initialState: chatAdapter.getInitialState({ initialState }),
  reducers: {
    setFilterState(state, { payload }: PayloadAction<IParams>) {
      state.initialState.filterState = payload;
    },
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
    },
    resetEntity(state) {
      state.initialState.updateEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.deleteEntitySuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getEntities.fulfilled.type,
      (state, { payload }: PayloadAction<AxiosResponse<IResponse<IChat[]>>>) => {
        chatAdapter.setAll(state, payload.data.data);
        state.initialState.totalItems = Number(payload.data.count);
        state.initialState.totalPages = Number(payload.data.total_pages);
        state.initialState.fetchEntitiesSuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(getEntities.rejected.type, (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    });
  },
});

export const { fetching, resetAll, resetEntity, setFilterState } = actions;
export default reducer;

export const chatSelectors = chatAdapter.getSelectors<RootState>((state) => state.chatReducer);

const { selectById } = chatAdapter.getSelectors();
const getChatState = (rootState: RootState) => rootState.chatReducer;

export const selectEntityById = (id: string) => {
  return createSelector(getChatState, (state) => selectById(state, id));
};
