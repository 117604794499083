import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';

const Chat = React.lazy(() => import('./Chat'));

const ChatLayout: RouteObject[] = [
  {
    path: '/',
    element: (
      // <ProtectedComponent requiredPerms={[]}>
      <Chat />
      // </ProtectedComponent>
    ),
  },
  {
    path: ':roomId',
    element: (
      // <ProtectedComponent requiredPerms={[]}>
      <Chat />
      // </ProtectedComponent>
    ),
  },
  { path: '*', element: <Navigate to="/404" /> },
];

const ChatRoutes = () => useRoutes(ChatLayout);
export default ChatRoutes;
