import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RootState } from '../../../reducers';
import { useRouter } from '../../../shared/utils/hooks/useRouter';
import { resetEntity } from './auth.reducer';

interface IRequireAuthProp {
  children: React.ReactNode;
}

export const RequireAuth = ({ children }: IRequireAuthProp) => {
  const { location } = useRouter();
  const { user, firstTimeLogin } = useSelector((state: RootState) => state.authentication);
  const [firstLogin, setFirstLogin] = useState(firstTimeLogin);
  const [admin, setAdmin] = useState(false);

  useEffect(() => {
    if (!firstTimeLogin) {
      const isFirstTime = localStorage.getItem('first_login');
      if (isFirstTime) setFirstLogin(JSON.parse(isFirstTime));
    }

    if (user?.role && (user?.role === 'ADMIN' || user?.role === 'CHAT')) {
      setAdmin(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstTimeLogin]);

  if (admin) {
    return <Navigate to="/admin" replace state={{ path: location.pathname }} />;
  }


  if (firstLogin) {
    return <Navigate to="/reset" replace state={{ path: location.pathname }} />;
  }

  if (!user) {
    // if (isBO) return <Navigate to="/auth/login" state={{ path: location.pathname }} />;
    return <Navigate to="/login" replace state={{ path: location.pathname }} />;
  }

  return <>{children}</>;
};
