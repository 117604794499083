import { SVGProps } from "react"
const UserIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props?.width||21}
    height={props?.height||20}
    fill="none"
    stroke="currentColor"
    {...props}
  >
    <path
      fill={props?.fill}
      d="M10.43 10a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5Z"
      opacity={0.12}
    />
    <path
      stroke={props?.stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.67}
      d="M10.43 12.5c-2.642 0-4.992 1.275-6.487 3.255-.322.426-.483.639-.478.927a.929.929 0 0 0 .319.64c.226.178.54.178 1.168.178h10.954c.628 0 .942 0 1.169-.178a.929.929 0 0 0 .319-.64c.005-.288-.156-.501-.478-.927-1.496-1.98-3.845-3.255-6.487-3.255ZM10.43 10a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5Z"
    />
  </svg>
)
export default UserIcon
