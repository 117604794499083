import { SVGProps } from "react"
const InfoCircleSidebar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props?.width||16}
    height={props?.height||16}
    fill="none"
    viewBox="0 0 16 16" 
    stroke="currentColor"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill={props?.fill}
        d="M7.999 14.667a6.667 6.667 0 1 0 0-13.334 6.667 6.667 0 0 0 0 13.334Z"
        opacity={0.12}
      />
      <path
        stroke={props?.stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.333}
        d="M7.999 10.667V8m0-2.667h.006M14.665 8A6.667 6.667 0 1 1 1.332 8a6.667 6.667 0 0 1 13.333 0Z"
      />
    </g>
  </svg>
)
export default InfoCircleSidebar
